import React from "react"
import Clamp from "components/clamp"

export default () => (
  <Clamp inset>
    <h3>GPI WEBSITE DISCLAIMER</h3>
    <h4>Terms and Conditions of Use</h4>
    <p>
      The information offered by GPI Capital, L.P. (“GPI Capital”) on this site
      is subject to certain terms and conditions (the “Terms and Conditions”).
      These Terms and Conditions apply to all users. By entering this site or by
      transmitting any information to the site, you acknowledge and agree to all
      of the Terms and Conditions. Please read them carefully. If you do not
      agree to the Terms and Conditions, please do not use this site. Any use of
      this site in a manner inconsistent with these Terms and Conditions is
      deemed unauthorized access and may subject the user to civil or criminal
      penalties. We reserve the right to revise this legal information at any
      time and for any reason, without notice or obligation, by updating this
      posting. Your continued use of this site following the posting of any
      changes to the Terms and Conditions will mean that you accept such
      amendments. We strongly recommend that you periodically visit this page of
      the site to review the Terms and Conditions.
    </p>

    <h4>Legal and Privacy Notices </h4>
    <p>
      GPI Capital is registered with the Securities and Exchange Commission
      (“SEC”) as an investment adviser under the Investment Advisers Act of
      1940, as amended. All information contained herein is for informational
      purposes and should not be construed as investment advice. This site is
      intended solely to provide information regarding potential financing
      capabilities of GPI Capital and its affiliates for prospective portfolio
      investments. The information provided on this page, including any
      information regarding GPI Capital’s current and historical portfolio
      investments, is not intended to recommend any investment described herein
      and is not an offer or sale of any security or investment product or
      investment advice. GPI Capital provides investment advisory services to
      the privately offered GPI funds. GPI Capital does not solicit or make its
      services available to the public or other advisory clients for purposes of
      investment in the GPI funds.
    </p>

    <h4>Disclaimers</h4>
    <p>
      While GPI Capital uses reasonable efforts to help ensure that all material
      on this site is correct, accuracy cannot be guaranteed and GPI Capital
      makes no representations or warranties as to the accuracy, reliability or
      completeness of any information at this site. Any contents at this site
      are subject to change without notice. THE SITE, AND ALL INFORMATION AND
      MATERIALS CONTAINED HEREIN, IS PROVIDED TO YOU “AS IS” WITHOUT WARRANTY OF
      ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE
      IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      TITLE AND NON-INFRINGEMENT TO THE FULL EXTENT PERMITTED BY LAW. GPI
      Capital further assumes no responsibility for, and makes no warranties
      that functions contained at this site will be uninterrupted or error-free,
      that defects will be corrected, or that the site or the server that makes
      it available will be free of viruses or other harmful components. GPI
      Capital shall not be liable for any damages, viruses, or services, repairs
      or corrections that must be performed on your computer or other property
      on account of your accessing or using this site. Please note that some
      jurisdictions do not allow the exclusion of implied warranties, so some or
      all of the above exclusions may not apply to you. GPI Capital does not
      warrant the accuracy, adequacy or completeness of the information and
      material contained on the site and expressly disclaims liability for
      errors or omissions in the material and information. GPI Capital is not
      liable for any consequential, incidental, special, punitive or exemplary
      damages arising out of any use of or inability to use the site or any
      portion thereof, regardless of whether GPI Capital has been apprised of
      the likelihood of such damages occurring and regardless of the form of
      action, whether in contract, warranty, tort (including negligence), strict
      liability, or otherwise. There may be legal requirements in various
      countries and jurisdictions which may restrict the information that can be
      accessed. Persons who obtain information from this site should inform
      themselves about and observe any restrictions imposed in the jurisdiction
      in which the site is accessed.
    </p>

    <h4>Material to be Consulted in its Entirety</h4>
    <p>
      All materials in this site are meant to be reviewed in their entirety,
      including any footnotes, legal disclaimers, restrictions or disclosures,
      and any copyright or proprietary notices. Any disclaimers, restrictions,
      disclosures or hedge clauses apply to any partial document or material in
      the same manner as they do to the whole, and will be deemed incorporated
      in the portion of any material or document that you consult or download.
    </p>

    <h4>Linking, Posting and Transmitting</h4>
    <p>
      Although GPI Capital provides the information accessible on the site for
      your personal, non-commercial use, GPI Capital retains all property
      rights, including, without limitation, property rights under U.S. and
      international copyright law, to all such information. Without the prior
      express written permission of GPI Capital, you MAY NOT do any of the
      following: (A) hyperlink to the site, whether to its homepage or to an
      interior page; (B) include information from the site on another site, on a
      server computer, or in documents; (C) modify or re-use the information
      from the site; or (D) transmit any commands, codes or information to the
      site, with the exception of those commands necessary to view the site. GPI
      Capital reserves all other rights. Do not transmit to the site or to any
      GPI Capital personnel, any pornographic, obscene, profane, defamatory,
      libelous, threatening, or unlawful material or any unsolicited commercial
      communications. Do not transmit to the site or to any GPI Capital
      personnel any material that could constitute or encourage unlawful conduct
      that would be considered a criminal offense, give rise to civil liability,
      or otherwise violate any law or regulation. Notwithstanding the fact that
      GPI Capital, or other parties involved in creating, producing, or
      delivering the site, may monitor or review any transmissions to the site
      or to GPI Capital personnel, GPI Capital and such other parties assume no
      responsibility or liability that may arise from the content thereof,
      including, without limitation, claims for defamation, libel, slander,
      obscenity, pornography, profanity, or misrepresentation. By entering the
      site you acknowledge and agree that any communication or material you
      transmit to the site or GPI Capital, in any manner and for any reason,
      will not be treated as confidential or proprietary. Furthermore, you
      acknowledge and agree that any materials you transmit to GPI Capital may
      be used by GPI Capital, anywhere, anytime and for any reason whatsoever.
      Although the site may be linked to other sites upon permission, GPI
      Capital is not, directly or indirectly, implying any approval,
      association, sponsorship, endorsement, or affiliation with the linked
      site, unless specifically stated therein. By entering the site you
      acknowledge and agree that GPI Capital has not reviewed all the sites that
      may be linked to the site and is not responsible for the content of any
      off-site pages or any other site linked to the site. You further
      acknowledge and agree that when you follow links to pages not maintained
      on the site, you do so at your own risk.
    </p>

    <h4>Privacy Policy</h4>
    <p>
      GPI Capital does not collect any personally identifiable information such
      as IP address, domain name, webpage, the length of time spent on the site,
      or the pages accessed while visiting the site. The information-gathering
      technology referred to as “cookies” will not be used in connection with
      the site. The only information that GPI Capital collects is that which is
      voluntarily submitted by users. By using the site you hereby understand
      and consent to the transfer of any data submitted by you to GPI Capital.
      Under no circumstances will GPI Capital exchange such information with
      third parties.
    </p>

    <h4>Copyright and Trademark Notice</h4>
    <p>
      GPI Capital is the copyright owner for everything on the site or has the
      permission to use the material on the site. No portion of the site,
      including, without limitation, the text or images may be used in any
      manner, or for any purpose, without the prior express written permission
      of GPI Capital, except as provided herein. The compilation of all content
      on the site is the exclusive property of GPI Capital and is protected by
      U.S. and international copyright laws. All software used on the site is
      the property of GPI Capital or its software suppliers and is protected by
      U.S. and international copyright laws. By entering the site you
      acknowledge and agree that each name, logo, trademark, or service mark
      contained on the site is owned or licensed by GPI Capital and may not be
      used by you without the prior written approval of GPI Capital. GPI Capital
      will aggressively enforce its intellectual property rights to the full
      extent of the law. Information or images of places or people are either
      the property of GPI Capital or used on the site by GPI Capital with
      permission. Your use of any such materials is prohibited unless
      specifically permitted by GPI Capital. Any unauthorized use of such
      materials may subject you to penalties or damages, including, without
      limitation, those related to violation of trademarks, copyrights, privacy
      and publicity rights.
    </p>

    <h4>Restricted Use of Site Materials</h4>
    <p>
      GPI Capital grants you a limited license to access and make use of this
      site. For your personal use only, you may print copies of the information
      from the site and you may store information from the site on your own
      computer. You may not modify the site, or any portion thereof, for any
      reason. Any unauthorized use terminates the permission or limited license
      granted by GPI Capital. GPI Capital reserves all other rights.
    </p>

    <h4>Limitations on Liability</h4>
    <p>
      By entering the site you acknowledge and agree that your use is at your
      own risk and that none of the parties involved in creating, producing, or
      delivering the site is liable for any direct, incidental, consequential,
      indirect, or punitive damages, or any other losses, costs, or expenses of
      any kind (including legal fees, expert fees, or other disbursements) that
      may arise, directly or indirectly, through access to the site, use or
      browsing of the site, or through your downloading of any text or images,
      from the site.
    </p>

    <h4>Applicable Law and Jurisdiction</h4>
    <p>
      By visiting the site, you agree that the laws of the State of New York,
      without regard to principles of conflict of laws, will govern these Terms
      and Conditions and any dispute of any sort that may arise between you and
      GPI Capital. You hereby consent to the jurisdiction of the federal and
      state courts in New York for the purpose of resolving any dispute in
      connection with your visit to the site. GPI Capital may seek injunctive or
      other appropriate relief in any state or federal court in the State of New
      York, and you consent to exclusive jurisdiction and venue in such court.
    </p>

    <h4>Severability</h4>
    <p>
      If any of the Terms and Conditions shall be deemed invalid, void, or for
      any reason unenforceable, such condition shall be deemed severable and
      shall not affect the validity and enforceability of any of the remaining
      Terms and Conditions.
    </p>

    <h4>Disclosures for Select Investments</h4>
    <p>
      The information included herein with respect to certain investments
      represents prior investments of senior investment professionals of GPI
      Capital while at other firms. This information is provided solely as an
      illustration of the types of investments made by such persons. Such
      information has not been provided, reviewed, endorsed or verified by the
      firms at which the applicable investment professional worked when the
      investments were made, and such firms are not responsible for the accuracy
      or completeness of such information. Persons who are not GPI Capital
      investment professionals or employees, and who are not and will not be
      involved with GPI funds, also worked on sourcing, acquiring, managing
      and/or disposing of these investments.
    </p>

    <h4>Additional Disclosure</h4>
    <p>
      This website was prepared for informational purposes only and should not
      be construed as legal, tax, investment or other advice. It does not
      constitute an offer to sell, or a solicitation of an offer to buy, any
      interest in any fund managed by GPI or any other security. Any such offer
      will be made only to qualified investors, only by means of a confidential
      offering memorandum and only in those jurisdictions where permitted by
      law. Consult your own attorney, business advisor and tax advisor as to
      legal, business and tax advice.
    </p>

    <p>
      The information contained herein does not purport to be complete, is
      qualified in its entirety by the applicable fund documentation and is
      subject to change without notice. All investments involve risk, including
      the loss of principal. Past performance is no guarantee of future results.
    </p>

    <p>
      The information in this website contains forecasts, projections, estimates
      and other forward-looking statements that are based on expectations and
      assumptions which existed at the time prepared, and may be accompanied by
      words such as “expects,” “forecasts,” “projects,” “intends,”
      “anticipates,” “estimates” and analogous expressions. Forward-looking
      statements are inherently subject to a variety of risks and uncertainties
      that can cause actual results to differ materially from those projected.
      Any forward-looking statements speak only as of the date on which they are
      first made. GPI does not undertake any obligation to update or alter any
      forward-looking statements whether as a result of changes in
      circumstances, new information, subsequent events or otherwise.
    </p>

    <p>
      The specific investments identified and described herein do not represent
      all of the investments purchased, sold or recommended for GPI-managed
      funds. It should not be assumed that all investments were or will be
      profitable. The characteristics of any particular GPI portfolio
      investment, including future portfolio investments, and GPI’ strategy with
      respect to any such portfolio investments, may differ materially from the
      portfolio investment’s characteristics and strategies described herein.
      There can be no assurance that historical trends will continue during the
      life of any investment.
    </p>
  </Clamp>
)